import * as React from 'react';
import { useContext } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'context/authContext';
import Header from 'layouts/MainLayout/components/Header';
import DrawerMenu from 'layouts/MainLayout/components/DrawerMenu';
import CircularLoading from 'components/CircularLoading';

const MainLayout = () => {
  const { me, error, loading } = useContext(AuthContext);
  if (loading) return <CircularLoading />;
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <DrawerMenu />
        <Box sx={{ width: me && !error ? 'calc(100vw - 250px)' : '100%', overflow: 'auto' }}>
          <Box sx={{ pt: 3, pb: 3, px: 4 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
