import { PaymentQuery, useAcquiringInstancesQuery, useUpdateCoursePaymentInfoMutation } from '@generated/graphql';
import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import { AutocompleteControl, Form, TextFieldControl } from 'components/inputs';
import { FC, useContext, useEffect } from 'react';
import { LK_URL } from 'constants/global';
import { useForm } from 'react-hook-form';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

interface IInputs {
  link?: string | undefined;
  course: PaymentQuery['course'];
}

const Payment: FC<{ course: PaymentQuery['course'] | null | undefined }> = ({ course }) => {
  const { addToast } = useContext(ToastContext);

  const form = useForm<IInputs>();
  const { reset, watch, handleSubmit } = form;

  const {
    data: acquiringData,
    loading: acquiringLoading,
    error: acquiringError
  } = useAcquiringInstancesQuery({
    variables: { limit: 10, page: 0 }
  });

  const acquiring = acquiringData?.acquiringInstances.data;

  useEffect(() => {
    if (course) {
      reset({
        link: course.id ? `${LK_URL}/payment/${course.id}` : '',
        course
      });
    }
  }, [course]);

  const [updatePaymentInfo] = useUpdateCoursePaymentInfoMutation();

  const onSubmit = (formData: IInputs) => {
    updatePaymentInfo({
      variables: {
        input: {
          id: formData.course.id,
          acquiringId: formData.course.acquiring?.id!,
          cost: typeof formData.course.cost === 'string' ? parseInt(formData.course.cost, 10) : formData.course.cost,
          colorThemeId: course?.colorThemeId!,
          grade: course?.grade!,
          subject: course?.subject!
        }
      }
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  return (
    <Form form={form}>
      <Grid container columnSpacing={2}>
        <Grid item sm={7}>
          <Stack spacing={2}>
            <Typography variant='h5'>Стоимость курса</Typography>
            <TextFieldControl name='course.cost' label='Стоимость приобретения, Р' />
            <TextFieldControl disabled label='Ссылка для приобретения' name='link' copyText={watch('link')} />
            <AutocompleteControl
              label='Способ оплаты'
              optionName='title'
              options={acquiring}
              name='course.acquiring'
              loading={acquiringLoading || !!acquiringError}
            />
          </Stack>
        </Grid>
        <Grid item sm={5}>
          <Alert variant='outlined' color='info'>
            Изменить текст, отображаемый при покупке, можно на странице карточек курса
          </Alert>
        </Grid>
      </Grid>
      <Button onClick={handleSubmit(onSubmit)} variant='contained' sx={{ mt: 2 }}>
        Сохранить
      </Button>
    </Form>
  );
};

export default Payment;
