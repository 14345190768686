import cookies from 'js-cookie';
import { addDays } from 'date-fns';

interface ICookie {
  name: string;
  value: string;
  options?: object;
}

export const setAuthCookies = (_cookies: ICookie[] = []) => {
  const currentHostname = window.location.hostname;
  _cookies.forEach((cookie) => {
    cookies.set(cookie.name, cookie.value, {
      expires: addDays(new Date(), 30),
      domain: currentHostname === 'localhost' ? undefined : `.${currentHostname}`,
      ...cookie.options
    });
  });
};

export const removeAuthCookies = (_cookies: string[] = []) => {
  const currentHostname = window.location.hostname;
  _cookies.forEach((cookie) => {
    cookies.remove(cookie, {
      path: '/',
      domain: currentHostname === 'localhost' ? undefined : `.${currentHostname}`
    });
  });
};

export const authRedirect = ({
  accessToken,
  refreshToken,
  redirectUrl
}: {
  accessToken: string;
  refreshToken: string;
  redirectUrl: string;
}) => {
  const currentCookies = [
    {
      name: 'accessToken',
      value: accessToken
    },
    {
      name: 'refreshToken',
      value: refreshToken
    }
  ];
  setAuthCookies(currentCookies);
  if (redirectUrl) {
    document.location.href = redirectUrl;
  } else {
    // eslint-disable-next-line no-restricted-globals
    document.location.href = `${location.origin}/courses`;
  }
};
