import { formatISODate } from 'helpers/date';
import { ROLE_NAME } from 'constants/global';
import { AdminUserListOrderFields, RoleName } from '@generated/graphql';
import { createUserName } from 'helpers/common';

const USERS_TABLE = [
  {
    title: 'ID',
    value: (user: any) => user.numericId || '—',
    loadingValue: '1355',
    sortName: AdminUserListOrderFields.NumericId
  },
  {
    title: 'ФИО',
    value: (user: any) => createUserName(user),
    loadingValue: 'Иванов Иван Иваныч'
  },
  {
    title: 'Роль',
    value: (user: { role: RoleName }) => (user.role ? ROLE_NAME[user.role] : '—'),
    loadingValue: '100'
  },
  {
    title: 'Дата создания',
    value: (user: any) => (user.createdAt ? formatISODate(user.createdAt) : '—'),
    loadingValue: '100'
  }
];

export default USERS_TABLE;
