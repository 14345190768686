import React, { FC, useContext } from 'react';
import { Box, BoxProps, Grid, Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/TextFieldControl';
import { UseFormReturn } from 'react-hook-form';
import FileAttachButton from 'components/inputs/FileAttachButton';
import ButtonWithFile from 'components/inputs/ButtonWithFile';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import {
  IFewAnswerProps,
  FewAnswer,
  SingleAnswer,
  TextAnswer,
  Matching,
  ISingleAnswerProps,
  // FileAnswer,
  ITextAnswerProp,
  IMatchingProps
  // IFileAnswerProps
} from '../answers';
import { ExerciseForm, ExtendedElementTypes, QuestionComponents } from '../../types';
import ScoreSettings from './ScoreSettings';

type QuestionProps = {
  elementIndex: number;
  disabled?: boolean;
  itemType: QuestionComponents;
  form: UseFormReturn<ExerciseForm>;
};

type ComponentType = {
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: FC<IFewAnswerProps>;
  [ExtendedElementTypes.QUESTION_MATCHING]: FC<IMatchingProps>;
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: FC<ITextAnswerProp>;
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: FC<ISingleAnswerProps>;
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: FC<BoxProps>;
};

const COMPONENT: ComponentType = {
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: FewAnswer,
  [ExtendedElementTypes.QUESTION_MATCHING]: Matching,
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: TextAnswer,
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: SingleAnswer,
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: Box
};

const Question: FC<QuestionProps> = ({ elementIndex, disabled, itemType, form }) => {
  const { addToast } = useContext(ToastContext);
  const Component = itemType ? COMPONENT[itemType] : Box;
  const { setValue, watch } = form;
  const watchFile = watch(`elements.${elementIndex}.files.0.file`);

  const deleteFile = () => {
    setValue(`elements.${elementIndex}.files`, undefined);
  };

  const onAttachFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? Array.from(event.target.files)[0] : undefined;
    if (newFile) {
      setValue(`elements.${elementIndex}.files.0.file`, newFile);
    } else {
      addToast({ type: ToastTypeEnum.ERROR, text: 'Файл не был добавлен' });
    }
  };

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack>
            <TextFieldControl
              minRows={4}
              multiline
              label='Описание'
              name={`elements.${elementIndex}.description`}
              rules={{
                validate: (value, formValues) => !!formValues.elements[elementIndex].title || !!value
              }}
              disabled={disabled}
            />
            <Stack direction='row' spacing={3} mt={2}>
              <FileAttachButton multiple text='Прикрепить файлы' onChange={onAttachFile} disabled={disabled} />
              {watchFile && (
                <ButtonWithFile
                  key={watchFile?.name}
                  onDelete={() => deleteFile()}
                  file={watchFile}
                  disabled={disabled}
                />
              )}
            </Stack>
            <Component {...{ elementIndex, itemType, form }} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <ScoreSettings elementIndex={elementIndex} elementType={itemType} form={form} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Question;
