import { Card, Grid, Stack, Typography } from '@mui/material';
import { ButtonWithFile, EditorControl, FileAttachButton, Form, TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import { useFieldArray, UseFieldArrayReturn, useForm } from 'react-hook-form';
import { useProcessAnswerMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

interface IFormInputs {
  score: string;
  message?: string;
  files?: any[];
}

const AnswerForm: FC<{
  maxScore: number;
  currentScore: number | undefined | null;
  refetchMessages: () => any;
}> = ({ maxScore, currentScore, refetchMessages }) => {
  const { userAnswerId } = useParams();

  const { addToast } = useContext(ToastContext);

  const form = useForm<IFormInputs>({ defaultValues: { score: currentScore ? currentScore.toString() : undefined } });
  const { handleSubmit, control } = form;

  const fieldArray = useFieldArray({
    control,
    name: 'files'
  }) as unknown as UseFieldArrayReturn;
  const { fields, append, remove } = fieldArray;

  const [processAnswer, { loading: processAnswerLoading }] = useProcessAnswerMutation();

  const onSend = (formInputs: IFormInputs) => {
    processAnswer({
      variables: {
        input: {
          id: userAnswerId!,
          message: formInputs.message,
          score: parseFloat(formInputs.score),
          files: formInputs.files?.length ? formInputs.files : undefined
        }
      }
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
        refetchMessages();
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };
  return (
    <Card component={Stack} spacing={2} sx={{ border: '2px solid rgb(25, 118, 210)' }}>
      <Typography variant='h5'>Ответ куратора</Typography>
      <Form form={form}>
        <Stack spacing={3.5} alignItems='baseline'>
          <TextFieldControl
            label='Количество баллов'
            name='score'
            rules={{ required: true }}
            type='number'
            helperText={`Максимальное количество баллов: ${maxScore}`}
          />
          <EditorControl name='message' label='Комментарий' />

          {!!fields.length && (
            <Grid container columnGap={1} rowGap={2}>
              {fields?.map((file: any, index: number) => (
                <ButtonWithFile file={file.file} onDelete={() => remove(index)} />
              ))}
            </Grid>
          )}

          <FileAttachButton
            onChange={(event: any) =>
              append({
                file: Array.from(event.target.files)[0]
              })
            }
            text='Добавить файл'
          />

          <LoadingButton loading={processAnswerLoading} onClick={handleSubmit(onSend)} variant='contained'>
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
    </Card>
  );
};

export default AnswerForm;
