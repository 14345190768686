import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/TextFieldControl';
import { Path, useFieldArray, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { ExerciseForm } from '../../types';

export interface ITextAnswerProp {
  elementIndex: number;
  disabled?: boolean;
  form: UseFormReturn<ExerciseForm>;
}

const TextAnswer: FC<ITextAnswerProp> = ({ elementIndex, disabled, form }) => {
  const fieldArrayName: Path<ExerciseForm> = `elements.${elementIndex}.elementParts`;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: fieldArrayName,
    keyName: 'formId'
  });

  const handleAddField = () => {
    append({ correctAnswer: undefined });
  };

  const handleRemoveField = (index: number) => {
    remove(index);
  };

  return (
    <Stack sx={{ mt: 3 }} spacing={2}>
      <TextFieldControl label='Подсказка в поле' name={`elements.[${elementIndex}].placeholder`} disabled={disabled} />
      {fields.map((field, index) => (
        <Stack direction='row'>
          <TextFieldControl
            key={field.formId}
            label='Правильный ответ'
            name={`${fieldArrayName}.${index}.correctAnswer`}
            rules={{ required: true }}
            disabled={disabled}
            multiline
          />
          <ActionButton
            sx={{ ml: '24px !important' }}
            handleClick={() => handleRemoveField(index)}
            type={ActionButtonEnum.DELETE}
          />
        </Stack>
      ))}
      <Button sx={{ mt: 3, width: 'fit-content' }} startIcon={<AddIcon />} onClick={handleAddField}>
        Добавить вариант ответа
      </Button>
    </Stack>
  );
};

export default TextAnswer;
