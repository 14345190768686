import { useCourseNavigationQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CircularLoading from 'components/CircularLoading';
import NavigationList from './NavigationList';

interface CourseNavigationProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentBlock: { id: string; title: string } | undefined;
}

const CourseNavigation = ({ open, setOpen, currentBlock }: CourseNavigationProps) => {
  const { courseId } = useParams();
  const { data, loading, error } = useCourseNavigationQuery({
    variables: {
      courseId: courseId!
    }
  });

  const onClose = () => setOpen(false);

  return (
    <Drawer open={open} onClose={onClose}>
      {loading ? (
        <CircularLoading />
      ) : (
        <NavigationList error={error} data={data} onCloseDrawer={onClose} currentBlock={currentBlock} />
      )}
    </Drawer>
  );
};

export default CourseNavigation;
