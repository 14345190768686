import { IconButton, Stack, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useContext } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { ToastContext, ToastContextProps, ToastTypeEnum } from 'context/toastContext';

interface InsertButtonProps<FormType extends FieldValues> {
  children: React.ReactNode;
  form: UseFormReturn<FormType>;
  inputName: Path<FormType>;
}

const InsertButton = <FormType extends FieldValues>({ children, form, inputName }: InsertButtonProps<FormType>) => {
  const { setValue } = form;
  const { addToast } = useContext<ToastContextProps>(ToastContext);

  const readClipboardText = async () => {
    try {
      return await navigator.clipboard.readText();
    } catch (e) {
      throw new Error('Error reading clipboard text');
    }
  };

  const insertValueInInput = (value: string) => {
    setValue(inputName, value as PathValue<FormType, Path<FormType>>);
  };

  const handleButtonClick = async () => {
    try {
      const text = await readClipboardText();
      insertValueInInput(text);
    } catch {
      addToast({ text: 'Произошла ошибка при вставке текста', type: ToastTypeEnum.ERROR });
    }
  };

  return (
    <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
      <Tooltip title='Вставить'>
        <IconButton sx={{ color: 'primary.main', ml: -1.25 }} onClick={handleButtonClick}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {children}
    </Stack>
  );
};

export default InsertButton;
