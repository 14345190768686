import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useFieldArray, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import TextFieldControl from 'components/inputs/TextFieldControl';
import CheckboxControl from 'components/inputs/CheckboxControl';
import Lesson from 'pages/Course/view/tabs/Content/components/Lesson';
import AddIcon from '@mui/icons-material/Add';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import { DatePickerControl, Dropzone } from 'components/inputs';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import deepEqual from 'fast-deep-equal/react';

type BlockProps = {
  form: UseFormReturn;
  blockIndex: number;
  disabled?: boolean;
  parentName: string;
};

const Block: FC<BlockProps> = ({ form, blockIndex, disabled, parentName }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = form;

  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: `${parentName}.lessons`
  }) as unknown as UseFieldArrayReturn;

  const blockLessons = watch(`${parentName}.lessons`);

  const blockLessonsEqual = (prevLessons: any[], nextLessons: any[]) => deepEqual(prevLessons, nextLessons);

  const trialLessonsCount = useMemo(
    () => blockLessons?.filter((lesson: any) => lesson.trial).length,
    [blockLessonsEqual]
  );

  useEffect(() => {
    setValue(
      `${parentName}.trial`,
      blockLessons?.every((lesson: any) => lesson.trial)
    );
  }, [blockLessonsEqual]);

  const { fields, append } = fieldArray;

  // @ts-ignore
  const blockError = !!errors?.blocks?.[blockIndex];

  const appendLesson = useCallback(() => {
    append({ title: '', trial: false });
  }, [append]);

  const coverFileUrl = watch(`${parentName}.coverFileUrl`);
  const resetImg = useCallback(() => {
    setValue(`${parentName}.coverFileUrl`, undefined);
    setValue(`${parentName}.coverFile`, null);
  }, [setValue, parentName]);

  return (
    <Stack
      spacing={1}
      name={`blocks-${blockIndex}`}
      sx={{
        border: (theme) => `1px solid ${blockError ? theme.palette.error.main : theme.palette.divider}`,
        p: 3,
        borderRadius: '10px',
        flexGrow: 1
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextFieldControl name={`${parentName}.title`} label='Название блока' />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2.5}>
          <DatePickerControl name={`${parentName}.dateFrom`} label='Доступен с' />
        </Grid>
        <Grid item xs={2.5}>
          <DatePickerControl name={`${parentName}.dateTo`} label='Доступен по' />
        </Grid>
      </Grid>
      <CheckboxControl
        label={`Доступен в тестовом доступе (${trialLessonsCount || 0})`}
        name={`${parentName}.trial`}
        handleChange={(value: boolean) =>
          blockLessons?.forEach((_: any, index: number) => {
            if (form.getValues(`${parentName}.lessons[${index}].trial`) !== value) {
              setValue(`${parentName}.lessons[${index}].trial`, value);
            }
          })
        }
      />
      <Stack width={200} height={100} position='relative' borderRadius='10px'>
        {coverFileUrl && (
          <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
            <img src={coverFileUrl} width='100%' height='100%' style={{ objectFit: 'cover' }} alt='обложка курса' />
          </Stack>
        )}
        <Box
          sx={{ opacity: coverFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
          width='100%'
          height='100%'
          overflow='hidden'
        >
          <Dropzone
            setValue={setValue}
            aspect={23 / 16}
            name={`${parentName}.coverFile`}
            nameUrl={`${parentName}.coverFileUrl`}
          />
        </Box>
        {coverFileUrl && (
          <ActionButton
            isFilled
            type={ActionButtonEnum.DELETE}
            handleClick={() => resetImg()}
            sx={{ position: 'absolute', right: 10, top: 10 }}
          />
        )}
      </Stack>

      {fields.map((lesson: any, index) => (
        <ButtonsWrapper
          key={`lesson-${lesson.formId}`}
          data={lesson}
          fieldArray={fieldArray}
          index={index}
          variant={ButtonVariantEnum.LESSON}
        >
          <Lesson
            blockIndex={blockIndex}
            form={form}
            lessonIndex={index}
            parentName={`${parentName}.lessons[${index}]`}
          />
        </ButtonsWrapper>
      ))}
      <Button startIcon={<AddIcon />} sx={{ width: 'fit-content' }} onClick={() => appendLesson()} disabled={disabled}>
        урок
      </Button>
    </Stack>
  );
};

export default Block;

Block.defaultProps = {
  disabled: false
};
