import { useController, UseControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { FC, ForwardedRef, ReactNode, useState } from 'react';
import * as React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IMask, IMaskInput } from 'react-imask';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface ITextFieldControl {
  name: string;
  endIcon?: ReactNode;
  handleChange?: (value: string, onChange: any) => any;
  label?: string;
  maskProps?: any;
  copyText?: string;
  maskType?: 'phone' | 'time' | 'fullTime';
}

const MaskCustom = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      unmask
    />
  );
});

const TextFieldControl: FC<Omit<TextFieldProps, 'label'> & ITextFieldControl & UseControllerProps> = ({
  name,
  placeholder,
  variant,
  size,
  rules,
  handleChange,
  maskProps,
  copyText,
  ...props
}) => {
  const {
    field,
    fieldState: { error }
  } = useController({ defaultValue: '', name, rules });
  const [showPassword, setShowPassword] = useState(false);
  const password = props.type === 'password';
  const isTimeInput = props.maskType === 'time' || props.maskType === 'fullTime';
  const generateEndIcon = () => {
    if (password) {
      return (
        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      );
    }
    if (props.endIcon) return props.endIcon;
    if (copyText) {
      return (
        <IconButton
          sx={{ color: 'primary.main', mr: -1.25 }}
          onClick={() => {
            navigator.clipboard.writeText(copyText);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      );
    }
    return undefined;
  };
  const endIcon = generateEndIcon();

  const generateInputProps = () => {
    if (isTimeInput) {
      return {
        mask: props.maskType === 'time' ? 'HH:MM' : 'HH:MM:SS',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2
          },
          ...(props.maskType === 'fullTime' && {
            SS: {
              mask: IMask.MaskedRange,
              placeholderChar: 'SS',
              from: 0,
              to: 59,
              maxLength: 2
            }
          })
        }
      };
    }
    if (maskProps) return maskProps;
    return {};
  };

  const inputProps = generateInputProps();

  const getInputType = () => {
    if (password) {
      if (!showPassword) return 'password';
      return 'text';
    }
    return props.type;
  };

  const inputType = getInputType();

  return (
    <TextField
      {...props}
      {...field}
      onChange={(e) => {
        const { value } = e.target;
        if (handleChange) handleChange(value, field.onChange);
        else field.onChange(value);
      }}
      type={inputType}
      helperText={error?.message || props.helperText}
      size={size || 'small'}
      error={!!error}
      fullWidth
      placeholder={placeholder}
      variant={variant}
      InputProps={{
        inputComponent: maskProps?.mask || isTimeInput ? MaskCustom : undefined,
        inputProps,
        endAdornment: endIcon ? <InputAdornment position='end'>{endIcon}</InputAdornment> : undefined
      }}
    />
  );
};

export default TextFieldControl;

TextFieldControl.defaultProps = {
  endIcon: undefined,
  handleChange: undefined,
  label: undefined,
  maskProps: undefined,
  copyText: undefined,
  maskType: undefined
};
