import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { HOMEWORK_STATUS_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = () => [
  {
    name: 'userNumericId',
    label: 'ID, пользователь',
    inputType: InputTypes.TEXT,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value)
  },
  {
    name: 'courseTitle',
    label: 'Название курса',
    inputType: InputTypes.TEXT
  },
  {
    name: 'courseSubject',
    label: 'Предмет',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: SUBJECT_OPTIONS,
    customFilter: (value: any) => value.id
  },
  {
    name: 'status',
    label: 'Статус',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: HOMEWORK_STATUS_OPTIONS,
    customFilter: (value: any) => value.id
  },
  {
    name: 'updatedAt',
    label: 'Дата изменения',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value)
  }
];

export default filterFields;
