import React, { ChangeEventHandler, FC } from 'react';
import { Button, SxProps } from '@mui/material';
import { AttachFileOutlined } from '@mui/icons-material';

export type FileAttachButtonProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  multiple?: boolean;
  text: string;
  accept?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
};

const FileAttachButton: FC<FileAttachButtonProps> = ({ onChange, multiple, text, accept, disabled, fullWidth, sx }) => (
  <Button
    component='label'
    fullWidth={fullWidth}
    startIcon={<AttachFileOutlined />}
    size='small'
    sx={{ cursor: 'pointer', ...sx }}
    disabled={disabled}
  >
    {text}
    <input
      accept={accept}
      multiple={multiple}
      type='file'
      style={{ display: 'none' }}
      onChange={onChange}
      disabled={disabled}
    />
  </Button>
);

export default FileAttachButton;
