import { Stack, Typography } from '@mui/material';
import { AdminUserAnswerListOrderFields, OrderType, useUsersAnswersQuery } from '@generated/graphql';
import TableLayout from 'layouts/TableLayout';
import React, { useEffect } from 'react';
import HOMEWORK_TABLE from './table';
import filterFields from './filterFields';

const HomeworkList = () => {
  const { data, loading, error, refetch } = useUsersAnswersQuery({
    variables: {
      page: 0,
      limit: 10,
      filter: {},
      order: { order: OrderType.Asc, field: AdminUserAnswerListOrderFields.UpdatedAt }
    }
  });

  useEffect(() => {
    document.title = 'Домашние задания';
  }, []);

  const homeworks = data?.usersAnswers?.data;
  const totalCount = data?.usersAnswers?.totalCount;
  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
        <Typography variant='h1'>Домашние задания</Typography>
      </Stack>
      <Stack spacing={3}>
        <TableLayout
          {...{ error, totalCount, refetch }}
          loading={loading}
          data={homeworks}
          tableStructure={HOMEWORK_TABLE}
          filtersFunc={filterFields}
          defaultOrder={{ order: 'desc', field: AdminUserAnswerListOrderFields.UpdatedAt }}
        />
      </Stack>
    </>
  );
};

export default HomeworkList;
