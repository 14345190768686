import {
  BlockAvailabilityType,
  CourseCardType,
  CourseSubject,
  ExerciseType,
  RoleName,
  UserAnswerStatus,
  UserBlockType,
  UserCourseType
} from '@generated/graphql';
import generateOptions from 'helpers/array';
import { colors } from '@mui/material';

const BOOLEAN_OPTIONS = [
  { displayName: 'Да', id: '1' },
  { displayName: 'Нет', id: '0' }
];

const SUBJECT_NAME: {
  [key in CourseSubject]: string;
} = {
  [CourseSubject.Biology]: 'Биология',
  [CourseSubject.Math]: 'Математика',
  [CourseSubject.Chemistry]: 'Химия',
  [CourseSubject.Physics]: 'Физика',
  [CourseSubject.History]: 'История',
  [CourseSubject.Informatics]: 'Информатика',
  [CourseSubject.Literature]: 'Литература',
  [CourseSubject.SocialScience]: 'Обществознание',
  [CourseSubject.RussianLanguage]: 'Русский язык'
};

const SUBJECT_OPTIONS = generateOptions(SUBJECT_NAME);
const EXERCISE_TYPE_NAME = {
  [ExerciseType.Homework]: 'Домашнее задание',
  [ExerciseType.Lecture]: 'Лекция'
};

const EXERCISE_TYPE_OPTIONS = generateOptions(EXERCISE_TYPE_NAME);

const ROLE_NAME = {
  [RoleName.Admin]: 'Администратор',
  [RoleName.User]: 'Ученик',
  [RoleName.Curator]: 'Куратор'
};

const ROLE_OPTIONS = generateOptions(ROLE_NAME);

const USER_COURSE_TYPE_NAME = {
  [UserCourseType.Full]: 'Полный',
  [UserCourseType.Trial]: 'Тестовый',
  [UserCourseType.Partial]: 'Частичный'
};

const USER_COURSE_TYPE_OPTIONS = generateOptions(USER_COURSE_TYPE_NAME);

const HOMEWORK_STATUS_NAME: { [key in UserAnswerStatus]: string } = {
  PENDING: 'Ожидает проверки',
  IN_PROGRESS: 'На проверке',
  COMPLETE: 'Проверено',
  REPLY_RECEIVED: 'Получен ответ',
  AUTOCOMPLETE: 'Автоматически проверено',
  DRAFT: 'Не выполнено'
};

const HOMEWORK_STATUS_COLOR: { [key in UserAnswerStatus]: string } = {
  PENDING: colors.blue[500],
  IN_PROGRESS: colors.grey[500],
  COMPLETE: colors.green[500],
  REPLY_RECEIVED: colors.orange[500],
  AUTOCOMPLETE: colors.grey[500],
  DRAFT: colors.grey[300]
};

const HOMEWORK_STATUS_OPTIONS = generateOptions(HOMEWORK_STATUS_NAME);

const GRADE_OPTIONS: { id: string; displayName: string }[] = [
  { id: '9', displayName: '9 класс' },
  { id: '10', displayName: '10 класс' },
  { id: '11', displayName: '11 класс' }
];

const COURSE_CARD_TITLE = {
  [CourseCardType.Advertising]: 'Реклама',
  [CourseCardType.Onboarding]: 'Онбординг',
  [CourseCardType.Presentation]: ' Приобретенный курс',
  [CourseCardType.Purchase]: 'Оплата'
};
const locationOrigin = window.location.origin;
const LK_URL = locationOrigin.includes('admin') ? process.env.REACT_APP_LK_URL : locationOrigin;

const AVAILABILITY_TYPE_TITLE = {
  [BlockAvailabilityType.Trial]: 'Тестовый',
  [BlockAvailabilityType.Payable]: 'Платный',
  [BlockAvailabilityType.Mixed]: 'Смешанный',
  [BlockAvailabilityType.Issued]: 'Выдан'
};

const USER_BLOCK_TYPE_TITLE = {
  [UserBlockType.Trial]: 'Тестовый',
  [UserBlockType.Paid]: 'Оплачен',
  [UserBlockType.Issued]: 'Выдан'
};

export {
  BOOLEAN_OPTIONS,
  SUBJECT_NAME,
  SUBJECT_OPTIONS,
  EXERCISE_TYPE_NAME,
  EXERCISE_TYPE_OPTIONS,
  ROLE_NAME,
  ROLE_OPTIONS,
  USER_COURSE_TYPE_NAME,
  USER_COURSE_TYPE_OPTIONS,
  HOMEWORK_STATUS_NAME,
  HOMEWORK_STATUS_OPTIONS,
  HOMEWORK_STATUS_COLOR,
  GRADE_OPTIONS,
  COURSE_CARD_TITLE,
  LK_URL,
  AVAILABILITY_TYPE_TITLE,
  USER_BLOCK_TYPE_TITLE
};
