import CircularLoading from 'components/CircularLoading';
import { Box, Grid, Stack } from '@mui/material';
import { DatePickerControl, Dropzone, Form, TextFieldControl } from 'components/inputs';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { useForm } from 'react-hook-form';
import { useUpdateUserDataMutation, useUserQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { format, isValid } from 'date-fns';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

type FormInputs = {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: Date | number | string | null;
  phoneNumber: string | undefined | null;
  comment?: string | undefined | null;
  avatarFile?:
    | {
        id: string;
        url: string;
      }
    | {
        name: string;
        preview: string;
      }
    | undefined
    | null;
  avatarFileUrl: string | undefined;
};

const PersonalInfoTab = () => {
  const { addToast } = useContext(ToastContext);
  const { userId } = useParams();

  const { data, loading } = useUserQuery({
    variables: {
      userId: userId!
    }
  });
  const user = data?.user;
  const form = useForm<FormInputs>({
    values: {
      ...user,
      phoneNumber: user?.phoneNumber?.startsWith('+') ? user.phoneNumber.slice(1) : user?.phoneNumber,
      avatarFileUrl: user?.avatarFile?.url
    }
  });
  const { setValue, handleSubmit, watch } = form;

  const [updateUser, { loading: updateLoading }] = useUpdateUserDataMutation();

  const onSubmit = (formData: FormInputs) => {
    const getAvatarFile = () => {
      if (!formData.avatarFile) return null;
      if ('preview' in formData.avatarFile && formData.avatarFile.preview) return formData.avatarFile;
      return undefined;
    };

    const avatarFile = getAvatarFile();

    updateUser({
      variables: {
        input: {
          birthDate:
            formData.birthDate && typeof formData.birthDate !== 'string' && isValid(formData.birthDate)
              ? format(formData.birthDate, 'yyyy-MM-dd')
              : formData.birthDate,
          comment: formData.comment,
          firstName: formData.firstName,
          lastName: formData.lastName,
          middleName: formData.middleName,
          id: userId!,
          phoneNumber: formData.phoneNumber,
          avatarFile
        }
      }
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const avatarFileUrl = watch('avatarFileUrl');
  const resetImg = () => {
    setValue('avatarFileUrl', undefined);
    setValue('avatarFile', null);
  };
  if (loading) return <CircularLoading />;
  return (
    <Form form={form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12} md={6} xl={4.5}>
          <Stack spacing={2}>
            <TextFieldControl name='lastName' label='Фамилия' />
            <TextFieldControl name='firstName' label='Имя' />
            <TextFieldControl name='middleName' label='Отчество' />
            <DatePickerControl name='birthDate' label='Дата рождения' />
            <TextFieldControl name='phoneNumber' label='Номер телефона' maskProps={{ mask: '+{7} (000) 000-00-00' }} />
            <TextFieldControl name='comment' label='Комментарий' multiline rows={3} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} xl={5.5}>
          <Stack width={300} height={300} position='relative' borderRadius='10px'>
            {avatarFileUrl && (
              <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
                <img
                  src={avatarFileUrl}
                  width='100%'
                  height='100%'
                  style={{ objectFit: 'cover' }}
                  alt='обложка курса'
                />
              </Stack>
            )}
            <Box
              sx={{ opacity: avatarFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
              width='100%'
              height='100%'
              overflow='hidden'
            >
              <Dropzone setValue={setValue} name='avatarFile' nameUrl='avatarFileUrl' />
            </Box>
            {avatarFileUrl && (
              <ActionButton
                isFilled
                type={ActionButtonEnum.DELETE}
                handleClick={() => resetImg()}
                sx={{ position: 'absolute', right: 10, top: 10 }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton type='submit' variant='contained' loading={updateLoading} loadingIndicator='Загрузка'>
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default PersonalInfoTab;
