import React, { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';

type DatePickerProps = {
  name: string;
  label?: string;
  rules?: object;
  defaultValue?: string;
  disabled?: boolean;
};

const DatePickerControl: FC<DatePickerProps> = ({ name, label, rules, defaultValue, disabled }) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules, defaultValue });
  return (
    <DatePicker
      value={field.value ? new Date(field.value) : null}
      onChange={field.onChange}
      views={['day', 'month', 'year']}
      format='dd.MM.yyyy'
      label={label}
      disabled={disabled}
      slotProps={{
        field: {
          clearable: true
        },
        textField: {
          size: 'small',
          fullWidth: true,
          error: !!error
        },
        openPickerButton: {
          sx: {
            marginRight: 0
          }
        }
      }}
    />
  );
};

export default DatePickerControl;

DatePickerControl.defaultProps = {
  rules: undefined,
  defaultValue: undefined,
  disabled: false,
  label: undefined
};
