import {
  CourseSubject,
  CreateOrUpdateCourseMutation,
  UpdateCourseInput,
  useColorThemesQuery,
  useCreateOrUpdateCourseMutation,
  useGetCourseDescriptionQuery
} from '@generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { Form, TextFieldControl, DatePickerControl, Dropzone, AutocompleteControl } from 'components/inputs';
import { useForm } from 'react-hook-form';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import { formatISODate } from 'helpers/date';
import { LoadingButton } from '@mui/lab';
import { formatISO, set } from 'date-fns';
import { GRADE_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoOutlined } from '@mui/icons-material';
import descriptionValidation from './validation';

interface ICourseDescriptionInputs {
  title: string | null | undefined;
  coverFile?: any;
  subject: { id: string; displayName: string } | undefined;
  colorTheme: { id: string | undefined; color: string | undefined } | undefined | null;
  dateFrom: string;
  coverFileUrl?: string;
  timeFrom: string;
  timeTo: string;
  dateTo: string;
  description: string | null | undefined;
  grade: { id: string; displayName: string } | undefined;
}

const COLOR_SCHEMES: { color: string; id: CourseSubject }[] = [
  {
    color: '#2FC373',
    id: CourseSubject.Biology
  },
  {
    color: '#AC80E4',
    id: CourseSubject.Chemistry
  },
  {
    color: '#FFCA2A',
    id: CourseSubject.Literature
  },
  {
    color: '#FF8C36',
    id: CourseSubject.Math
  },
  {
    color: '#FF5789',
    id: CourseSubject.RussianLanguage
  },
  {
    color: '#FB4BCA',
    id: CourseSubject.SocialScience
  },
  {
    color: '#8A70FF',
    id: CourseSubject.Physics
  },
  {
    color: '#3DD9BD',
    id: CourseSubject.Informatics
  },
  {
    color: '#0E91F0',
    id: CourseSubject.History
  }
];

const Description = () => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { courseId } = useParams<{ courseId: string }>();

  const { data, loading, error } = useGetCourseDescriptionQuery(
    courseId
      ? {
          variables: {
            courseId
          }
        }
      : {
          skip: !courseId
        }
  );

  const course = data?.course;

  const { data: colorScheme } = useColorThemesQuery({
    variables: { limit: 10, page: 0 }
  });

  const form = useForm<ICourseDescriptionInputs>({
    resolver: yupResolver<any>(descriptionValidation),
    values: {
      dateFrom: course?.dateFrom,
      dateTo: course?.dateTo,
      title: course?.title,
      description: course?.description,
      coverFile: course?.coverFile,
      timeFrom: course?.dateFrom ? formatISODate(course?.dateFrom, 'HHmm') : '0000',
      timeTo: course?.dateTo ? formatISODate(course?.dateTo, 'HHmm') : '0000',
      coverFileUrl: course?.coverFile?.url,
      colorTheme: COLOR_SCHEMES.find((scheme) => scheme.id === course?.subject),
      subject: SUBJECT_OPTIONS.find((item: any) => item.id === course?.subject),
      grade: GRADE_OPTIONS.find((item: any) => item.id === course?.grade.toString())
    }
  });
  const { setValue, watch, handleSubmit } = form;

  const coverFileUrl = watch('coverFileUrl');

  const resetImg = () => {
    setValue('coverFileUrl', undefined);
    setValue('coverFile', null);
  };

  const [updateCourse, { loading: updateLoading }] = useCreateOrUpdateCourseMutation();

  const onSubmit = (formData: ICourseDescriptionInputs) => {
    const { dateFrom, dateTo, timeFrom, timeTo, description, title } = formData;
    const generateDate = (date: string, time: string) => {
      if (!date || !time) return undefined;
      const [hours, minutes] = [time.slice(0, 2), time.slice(2).replace(':', '')];
      return formatISO(set(new Date(date), { hours: Number(hours), minutes: Number(minutes) }));
    };
    const formattedDateFrom = generateDate(dateFrom, timeFrom);
    const formattedDateTo = generateDate(dateTo, timeTo);
    const getCoverFile = () => {
      if (!formData.coverFile) return null;
      if (formData.coverFile.preview) return formData.coverFile;
      return undefined;
    };
    const coverFile = getCoverFile();

    const input = {
      colorThemeId: colorScheme?.colorThemes.data?.[0].id,
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      description,
      title,
      id: courseId,
      subject: formData.subject!.id,
      grade: parseFloat(formData.grade!.id),
      coverFile,
      cost: course?.cost || 0,
      acquiringId: course?.acquiring?.id
    } as UpdateCourseInput;

    updateCourse({
      variables: {
        input
      }
    })
      .then((res) => {
        const resData: CreateOrUpdateCourseMutation | undefined | null = res.data;
        navigate(`../${resData?.createOrUpdateCourse.id}`);
        addToast({ type: ToastTypeEnum.SUCCESS });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const subjectWatch = watch('subject');
  useEffect(() => {
    if (subjectWatch?.id) {
      setValue(
        'colorTheme',
        COLOR_SCHEMES.find((scheme) => scheme.id === subjectWatch.id)
      );
    }
  }, [subjectWatch]);

  if (loading || !!error) return <CircularLoading />;

  return (
    <Form form={form} onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12} md={6} xl={4.5}>
          <Stack spacing={2}>
            <TextFieldControl name='title' label='Название курса' />
            <AutocompleteControl label='Предмет' options={SUBJECT_OPTIONS} name='subject' />
            <AutocompleteControl
              label='Цвет курса'
              optionName='color'
              options={COLOR_SCHEMES}
              name='colorTheme'
              disabled
              startIcon={
                <Box
                  sx={{
                    ml: 2,
                    width: 16,
                    height: 16,
                    backgroundColor: COLOR_SCHEMES.find((scheme) => scheme.id === subjectWatch?.id)?.color
                  }}
                />
              }
            />
            <AutocompleteControl label='Класс' options={GRADE_OPTIONS} name='grade' />
            <Stack direction='row' spacing={1}>
              <DatePickerControl name='dateFrom' label='Дата начала курса' />
              <TextFieldControl name='timeFrom' sx={{ width: '50%' }} label='Время начала' maskType='time' />
            </Stack>
            <Stack direction='row' spacing={1}>
              <DatePickerControl name='dateTo' label='Дата окончания курса' />
              <TextFieldControl name='timeTo' sx={{ width: '50%' }} label='Время окончания' maskType='time' />
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <TextFieldControl name='description' label='Описание курса' multiline minRows={1} />
              <Tooltip title='Этот текст будет выведен в шапке онбординга. Результат можно увидеть в разделе Карточки - Онбординг.'>
                <InfoOutlined sx={{ color: 'base.300' }} />
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md='auto' sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Stack width={300} height={300} position='relative' borderRadius='10px'>
            {coverFileUrl && (
              <Stack position='absolute' width='100%' height='100%' overflow='hidden'>
                <img src={coverFileUrl} width='100%' height='100%' style={{ objectFit: 'cover' }} alt='обложка курса' />
              </Stack>
            )}
            <Box
              sx={{ opacity: coverFileUrl ? 0 : 1, '&:hover': { opacity: 0.8 } }}
              width='100%'
              height='100%'
              overflow='hidden'
            >
              <Dropzone setValue={setValue} />
            </Box>
            {coverFileUrl && (
              <ActionButton
                isFilled
                type={ActionButtonEnum.DELETE}
                handleClick={() => resetImg()}
                sx={{ position: 'absolute', right: 10, top: 10 }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton type='submit' variant='contained' loading={updateLoading} loadingIndicator='Загрузка'>
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Description;
