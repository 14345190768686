import React, { useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import TableLayout from 'layouts/TableLayout';
import COURSES_TABLE from 'pages/Course/list/table';
import filterFields from 'pages/Course/list/filterFields';
import { useCoursesQuery } from '@generated/graphql';

const Courses = () => {
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useCoursesQuery({ variables: { page: 0, limit: 10 } });

  const courses = data?.courses?.data;
  const totalCount = data?.courses?.totalCount;

  useEffect(() => {
    document.title = 'Курсы';
  }, []);

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
        <Typography variant='h1'>Курсы</Typography>
        <Button onClick={() => navigate(ROUTES.CREATE)} variant='contained'>
          Создать курс
        </Button>
      </Stack>
      <Stack spacing={3}>
        <TableLayout
          {...{ error, totalCount, refetch }}
          loading={loading}
          data={courses}
          tableStructure={COURSES_TABLE}
          filtersFunc={filterFields}
        />
      </Stack>
    </>
  );
};

export default Courses;
