import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CKEditor } from '@ckeditor/ckeditor5-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Box } from '@mui/material';

type EditorProps = {
  onChange: (data: string) => any;
  value: string;
  error: any;
  disabled?: boolean;
};

const EditorComponent: FC<EditorProps> = ({ onChange, value, error, disabled }) => (
  <Box
    sx={{
      '& .ck-content': {
        height: 300
      },
      '& .ck.ck-editor__main>.ck-editor__editable': {
        borderColor: error && 'error.main'
      }
    }}
  >
    <CKEditor
      editor={Editor}
      disabled={disabled}
      config={{
        // @ts-ignore
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        fontBackgroundColor: {
          colors: [
            {
              color: '#956AEE',
              label: 'Purple'
            }
          ]
        },
        fontColor: {
          colors: [
            {
              color: '#956AEE',
              label: 'Purple'
            },
            {
              color: '#ffffff',
              label: 'White'
            }
          ]
        }
      }}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        // onChange({event, editor, data});
        onChange(data);
      }}
    />
  </Box>
);

export default EditorComponent;

EditorComponent.defaultProps = {
  disabled: false
};
