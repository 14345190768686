import React, { FC, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCrop, { Crop, makeAspectCrop, centerCrop, PixelCrop } from 'react-image-crop';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import onCreateCroppedPreview from './onCreateCroppedPreview';
import PhotoUploadModal from './PhotoUploadModal';

type DropzoneProps = {
  setValue: any;
  disabled?: boolean;
  height?: number;
  width?: number;
  text?: string;
  name?: string;
  nameUrl?: string;
  aspect?: number;
};

const Dropzone: FC<DropzoneProps> = ({ setValue, disabled, height, width, text, name, nameUrl, aspect = 1 }) => {
  const [file, setFile] = useState<any>();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [crop, setCrop] = useState<Crop>();

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const onClose = () => {
    setOpenCropModal(false);
  };
  const uploadNewAvatar = (newFile: any) => {
    setValue(name || 'coverFile', newFile);
    setValue(nameUrl || 'coverFileUrl', newFile.preview);
    onClose();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => setFile(reader.result));
      reader.readAsDataURL(acceptedFiles[0]);
      setOpenCropModal(true);
    },
    accept: {
      'image/*': []
    },
    useFsAccessApi: !disabled
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }

  return (
    <Box sx={{ height: height || '100%' }}>
      <div {...getRootProps({})} style={{ height: height || '100%' }}>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            cursor: disabled ? undefined : 'pointer',
            height: height || '100%',
            width: width || '100%',
            bgcolor: 'base.200',
            borderRadius: 2
          }}
        >
          <input {...getInputProps()} accept='image/*' disabled={disabled} />
          <DriveFolderUploadIcon />
          <Typography whiteSpace='pre-wrap'>{`Загрузить ${text || 'обложку'}`}</Typography>
        </Stack>
      </div>
      <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
      {openCropModal && (
        <PhotoUploadModal
          open={openCropModal}
          onClose={onClose}
          content={
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={100}
            >
              <img
                ref={imgRef}
                alt='Crop me'
                src={file}
                style={{
                  height: '100%',
                  width: '100%',
                  minWidth: '400px'
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          }
          onAction={() => {
            onCreateCroppedPreview({
              completedCrop,
              previewCanvasRef,
              imgRef,
              uploadNewAvatar
            });
          }}
        />
      )}
    </Box>
  );
};

export default Dropzone;

Dropzone.defaultProps = {
  disabled: false,
  text: undefined,
  height: undefined,
  width: undefined,
  name: undefined,
  nameUrl: undefined,
  aspect: 1
};
