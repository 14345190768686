import { useCourseUsersQuery } from '@generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import TableLayout from 'layouts/TableLayout';
import USERS_TABLE from 'pages/Course/view/tabs/Users/table';
import filterFields from 'pages/Course/view/tabs/Users/filterFields';

const Users = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<{ courseId: string }>();
  const defaultFilters = {
    courseId: courseId!
  };
  const { data, loading, error, refetch } = useCourseUsersQuery({
    variables: {
      courseId: courseId!,
      filter: {
        courseId: courseId!
      },
      limit: 10,
      page: 0
    }
  });

  const users = data?.courseUsers.data;
  const totalCount = data?.courseUsers.totalCount;
  const course = data?.course;
  return (
    <TableLayout
      data={users}
      refetch={refetch}
      loading={loading}
      totalCount={totalCount}
      tableStructure={USERS_TABLE(refetch, course)}
      defaultFilter={defaultFilters}
      error={error}
      filtersFunc={filterFields}
      action={(user) => navigate(`user/${user.id}`)}
    />
  );
};

export default Users;
