import CircularLoading from 'components/CircularLoading';
import { Link, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import TabLayout from 'layouts/TabLayout';
import { useGetCourseHeaderQuery } from '@generated/graphql';
import Content from 'pages/Course/view/tabs/Content';
import HeaderButtons from 'pages/Course/view/components/HeaderButtons';
import Curators from 'pages/Course/view/tabs/Curators';
import Users from 'pages/Course/view/tabs/Users';
import Payment from 'pages/Course/view/tabs/Payment';
import React, { useEffect } from 'react';
import Description from './tabs/Description';

const TABS = [
  { title: 'Описание', value: 'description', component: <Description /> },
  { title: 'Содержание', value: 'content', component: <Content /> },
  { title: 'Кураторы', value: 'curators', component: <Curators /> },
  { title: 'Пользователи', value: 'users', component: <Users /> },
  { title: 'Оплата', value: 'payment', component: <Payment /> }
];

const CourseView = () => {
  const { courseId } = useParams();
  const { data, loading, error } = useGetCourseHeaderQuery(
    courseId
      ? {
          variables: {
            courseId
          }
        }
      : {
          skip: !courseId
        }
  );
  const course = data?.course;
  const generateTabTitle = () => {
    if (loading) return 'Курс';
    if (course?.title) return `Курс ${course.title}`;
    return 'Создание курса';
  };

  const tabTitle = generateTabTitle();

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);
  return (
    <Stack>
      {loading || !!error ? (
        <CircularLoading />
      ) : (
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Stack spacing={2}>
            <Typography variant='h1' sx={{ fontWeight: 500 }}>
              {`Курс${courseId ? ` ${course?.title}` : ''}`}
            </Typography>
            {courseId ? (
              <Typography variant='h6' sx={{ fontWeight: 500 }}>
                {`ID ${course?.numericId}`}
              </Typography>
            ) : null}
          </Stack>
          {course && (
            <Stack spacing={2} alignItems='flex-end'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <HeaderButtons course={course} />
              </Stack>
              <Button size='small' variant='contained' sx={{ width: 'fit-content' }} component={Link} to='cards'>
                Карточки
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      <TabLayout searchParam={courseId} tabs={TABS} endText={course?.isHidden ? 'Курс скрыт' : 'Курс опубликован'} />
    </Stack>
  );
};

export default CourseView;
