import { TypographyOptions } from '@mui/material/styles/createTypography';

export default {
  fontFamily: 'Inter',
  h1: {
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700
  },
  h2: {
    fontSize: 26,
    lineHeight: 1.2,
    fontWeight: 700
  },
  h3: {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600
  },
  h4: {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 500
  },
  h5: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 600
  },
  h6: {
    fontSize: 18,
    lineHeight: 1,
    fontWeight: 500
  },
  text1: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 500
  },
  text2: {
    fontSize: 18,
    lineHeight: 1.3,
    fontWeight: 600
  },
  'text2.1': {
    fontSize: 18,
    lineHeight: 1,
    fontWeight: 700
  },
  'text2.2': {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 500
  },
  text3: {
    fontSize: 16,
    lineHeight: 1.3,
    fontWeight: 500
  },
  text4: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 500
  },
  text5: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 400
  },
  text6: {
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 500
  },
  text7: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500
  }
} as TypographyOptions;
