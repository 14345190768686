import React, { FC, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFieldArray, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import TextFieldControl from 'components/inputs/TextFieldControl';
import CheckboxControl from 'components/inputs/CheckboxControl';
import Exercise from 'pages/Course/view/tabs/Content/components/Exercise';
import AddIcon from '@mui/icons-material/Add';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import { DatePickerControl } from 'components/inputs';

type LessonProps = {
  form: UseFormReturn;
  lessonIndex: number;
  blockIndex: number;
  disabled?: boolean;
  parentName: string;
};

const Lesson: FC<LessonProps> = ({ form, lessonIndex, parentName, blockIndex, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    watch,
    formState: { errors },
    control
  } = form;
  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: `${parentName}.exercises`
  }) as unknown as UseFieldArrayReturn;
  const { fields, append } = fieldArray;

  const lesson = watch(parentName);

  // @ts-ignore
  const blockError = !!errors?.blocks?.[blockIndex]?.lessons?.[lessonIndex];

  useEffect(() => {
    if (blockError) {
      setIsOpen(true);
    }
  }, [blockError]);

  const appendLessonPart = () => {
    append({ title: '', trial: false });
  };

  return (
    <Accordion
      sx={{
        width: '100%',
        border: (theme) => `1px solid ${blockError ? theme.palette.error.main : theme.palette.divider}`
      }}
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between' } }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant='h6'>{`${lessonIndex + 1}. Урок ${lesson?.title && `«${lesson.title}»`}`}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {isOpen && (
          <Stack spacing={3} sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextFieldControl label='Название урока' name={`${parentName}.title`} disabled={disabled} />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2.5}>
                <DatePickerControl name={`${parentName}.dateFrom`} label='Доступен с' />
              </Grid>
              <Grid item xs={2.5}>
                <DatePickerControl name={`${parentName}.dateTo`} label='Доступен по' />
              </Grid>
            </Grid>
            <CheckboxControl disabled={disabled} label='Доступен в тестовом доступе' name={`${parentName}.trial`} />
            {fields?.map((lessonPart: any, index) => (
              <ButtonsWrapper
                key={`lesson-part-${lessonPart.formId}`}
                data={lessonPart}
                fieldArray={fieldArray}
                index={index}
                variant={ButtonVariantEnum.LESSON_PART}
              >
                <Exercise parentName={`${parentName}.exercises[${index}]`} form={form} />
              </ButtonsWrapper>
            ))}
            <Button
              sx={{ width: 'fit-content' }}
              onClick={() => appendLessonPart()}
              disabled={disabled}
              startIcon={<AddIcon />}
            >
              Добавить часть урока
            </Button>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Lesson;

Lesson.defaultProps = {
  disabled: false
};
