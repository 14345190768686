import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import { CourseNavigationQuery } from '@generated/graphql';
import { ApolloError } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExerciseItem from './ExerciseItem';

interface NavigationListProps {
  error: ApolloError | undefined;
  data: CourseNavigationQuery | undefined;
  onCloseDrawer: () => void;
  currentBlock: { id: string; title: string } | undefined;
}

const NavigationList = ({ error, data, onCloseDrawer, currentBlock }: NavigationListProps) => {
  const blocks = data?.course.blocks;

  if (error || !blocks) return <Typography>Ошибка загрузки навигации курса</Typography>;
  return (
    <Box sx={{ width: 400, pt: 10, pb: 2 }} role='presentation'>
      <Stack spacing={1}>
        {blocks?.map((block) => (
          <Accordion key={`block-${block.id}`} defaultExpanded={block.id === currentBlock?.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>{`Блок: ${block.title}`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 0 }}>
              {block.lessons?.map((lesson) => (
                <Stack key={`lesson-${lesson.id}`} sx={{ pl: 1 }}>
                  <Typography>{lesson.title}</Typography>
                  <Box component='ul' sx={{ my: 0 }}>
                    {lesson.exercises?.map((exercise) => (
                      <ExerciseItem onCloseDrawer={onCloseDrawer} exercise={exercise} />
                    ))}
                  </Box>
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>
  );
};

export default NavigationList;
