import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { FormHTMLAttributes, ReactNode } from 'react';

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  children: ReactNode;
};

const Form = <T extends FieldValues>(props: FormHTMLAttributes<HTMLFormElement> & FormProps<T>) => {
  const { form, children, onSubmit, ...rest } = props;
  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={(e) => {
          if (typeof onSubmit === 'function') onSubmit(e);
          e.preventDefault();
        }}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
