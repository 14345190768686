import { Card, Stack, Typography, Link } from '@mui/material';
import { createUserName } from 'helpers/common';
import { UserAnswerQuery, UserAnswerStatus, useUserAnswerQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { ButtonWithFile } from 'components/inputs';
import ROUTES from 'constants/routes';
import { formatISODate } from 'helpers/date';
import AnswerForm from './components/AnswerForm';
import HistoryMessages from './components/HistoryMessages';

type UserAnswerElement = NonNullable<UserAnswerQuery['userAnswer']>['element'] | undefined;

const Exercise = () => {
  const { userAnswerId } = useParams<{ userAnswerId: string }>();

  const { data, loading, refetch } = useUserAnswerQuery({ variables: { userAnswerId: userAnswerId! } });

  const userAnswer = data?.userAnswer;
  const user = userAnswer?.user;
  const element: UserAnswerElement = userAnswer?.element;
  const status = userAnswer?.status;
  const studentId = userAnswer?.user.id;
  const lessonTitle = userAnswer?.element.exercise.lesson.title;
  const blockTitle = userAnswer?.element.exercise.lesson.block.title;
  const courseTitle = userAnswer?.element.exercise.lesson.block.course.title;

  const elementTitle = element && 'title' in element ? element.title : undefined;
  const elementDescription = element && 'description' in element ? element.description : undefined;
  const elementMaxScore = element && 'maxScore' in element ? element.maxScore : undefined;

  const userAnswerHistory = data?.userAnswerHistory || [];

  const currentScore = [...userAnswerHistory]?.reverse()?.find((history) => history.score !== null)?.score;

  const messages = userAnswerHistory.filter((item) => item.message || item.files?.length);

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2}>
      <Card component={Stack} direction='row'>
        <Stack spacing={1} flex={2}>
          <Typography variant='h6'>{`Курс: ${courseTitle}`}</Typography>
          <Typography variant='h6'>{`Блок: ${blockTitle}`}</Typography>
          <Typography variant='h6'>{`Урок: ${lessonTitle}`}</Typography>
        </Stack>
        <Stack spacing={1} flex={1}>
          <Typography>{`ID: ${user?.numericId}`}</Typography>
          <Link href={`/${ROUTES.USERS}/${user?.id}`} rel='noopener noreferrer' variant='body1'>
            {`ФИО: ${createUserName(user)}`}
          </Link>
        </Stack>
      </Card>
      <Card>
        <Stack spacing={1}>
          <Typography variant='h6'>{elementTitle}</Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{elementDescription}</Typography>
        </Stack>
      </Card>
      <Card component={Stack} spacing={2}>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='h5' mb={2}>
            Ответ пользователя
          </Typography>
          {!!userAnswer?.createdAt && (
            <Typography variant='text6'>{formatISODate(userAnswer?.createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
          )}
        </Stack>
        {userAnswer?.file && <ButtonWithFile file={userAnswer.file} />}
      </Card>
      <HistoryMessages messages={messages} studentId={studentId!} />
      {(status === UserAnswerStatus.InProgress || status === UserAnswerStatus.ReplyReceived) && (
        <AnswerForm maxScore={elementMaxScore || 0} currentScore={currentScore} refetchMessages={refetch} />
      )}
    </Stack>
  );
};

export default Exercise;
