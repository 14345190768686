import { useProcessAnswerMutation, UserAnswerStatus, useUserAnswerQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import TabLayout from 'layouts/TabLayout';
import { Chip, Stack, Typography } from '@mui/material';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME } from 'constants/global';
import { LoadingButton } from '@mui/lab';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useContext, useEffect } from 'react';
import Exercise from './tabs/Exercise';
import History from './tabs/History';

/* eslint-disable no-underscore-dangle */

const TABS = [
  { title: 'Задание', value: 'exercise', component: <Exercise /> },
  { title: 'История', value: 'history', component: <History /> }
];

const HomeworkView = () => {
  const { addToast } = useContext(ToastContext);

  const { userAnswerId } = useParams();
  const { data, loading } = useUserAnswerQuery({
    variables: {
      userAnswerId: userAnswerId!
    }
  });

  const userAnswer = data?.userAnswer;
  const status = userAnswer?.status;

  const [processAnswer, { loading: processAnswerLoading }] = useProcessAnswerMutation();

  const onChangeStatus = () => {
    processAnswer({
      variables: {
        input: {
          id: userAnswerId!,
          inProgress: status === UserAnswerStatus.Pending || undefined,
          complete: status === UserAnswerStatus.ReplyReceived || undefined
        }
      }
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  useEffect(() => {
    document.title = 'Домашнее задание';
  }, []);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Stack direction='row' spacing={2} justifyContent='space-between'>
        <Typography variant='h1'>Домашнее задание</Typography>
        <Stack spacing={2}>
          {status && (
            <Chip label={HOMEWORK_STATUS_NAME[status]} sx={{ backgroundColor: HOMEWORK_STATUS_COLOR[status] }} />
          )}
          {(status === UserAnswerStatus.Pending || status === UserAnswerStatus.ReplyReceived) && (
            <LoadingButton loading={processAnswerLoading} onClick={onChangeStatus} variant='contained'>
              {status === UserAnswerStatus.Pending ? 'На проверку' : 'Проверено'}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <TabLayout searchParam={userAnswerId} tabs={TABS} firstTab='exercise' />
    </>
  );
};
export default HomeworkView;
