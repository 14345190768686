const createUserName = (
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  } | null
): string => {
  if (!user) return '—';

  const { firstName, lastName, middleName } = user;

  const names = [firstName, lastName, middleName].filter((name): name is string => !!name && name.trim() !== '');

  return names.length > 0 ? names.join(' ') : '—';
};

const getFileName = (fileNameWithExtension: string | undefined | null, type: 'name' | 'extension'): string => {
  if (!fileNameWithExtension) return 'fileName';

  const dotIndex = fileNameWithExtension.lastIndexOf('.');

  if (dotIndex === -1) {
    return type === 'name' ? fileNameWithExtension : '';
  }

  if (type === 'name') {
    return fileNameWithExtension.substring(0, dotIndex);
  }
  if (type === 'extension') {
    return fileNameWithExtension.substring(dotIndex + 1);
  }

  return '';
};

export { createUserName, getFileName };
