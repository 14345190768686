import React, { FC, useContext } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import FileAttachButton from 'components/inputs/FileAttachButton';
import { getFileName } from 'helpers/common';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { CurrentFileFieldName, ExerciseForm } from '../../../types';

interface AttachDownloadFileProps {
  form: UseFormReturn<ExerciseForm>;
  fieldArray: UseFieldArrayReturn;
  index: number;
  disabled?: boolean;
  isCover: boolean;
  currentName: CurrentFileFieldName;
}

const AttachDownloadFile: FC<AttachDownloadFileProps> = ({
  form,
  fieldArray,
  currentName,
  isCover,
  index,
  disabled
}) => {
  const { addToast } = useContext(ToastContext);
  const { getValues } = form;
  const { update } = fieldArray;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? Array.from(event.target.files)[0] : undefined;
    if (newFile) {
      const currentData = getValues(currentName) || {};
      const fileName = getFileName(newFile.name, 'name');
      const updatedData = {
        ...currentData,
        file: newFile,
        fileName
      };
      update(index, updatedData);
    } else {
      addToast({ type: ToastTypeEnum.ERROR, text: 'Файл не был добавлен' });
    }
  };

  return (
    <FileAttachButton
      text={isCover ? 'Прикрепить обложку' : 'Прикрепить файл'}
      onChange={handleFileChange}
      disabled={disabled}
    />
  );
};

export default AttachDownloadFile;
