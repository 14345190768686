import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { BOOLEAN_OPTIONS, SUBJECT_OPTIONS } from 'constants/global';
import { formatForFilter } from 'helpers/date';

const filterFields = () => [
  {
    name: 'numericId',
    label: 'ID',
    inputType: InputTypes.TEXT,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value)
  },
  {
    name: 'title',
    label: 'Название',
    inputType: InputTypes.TEXT
  },
  {
    name: 'subject',
    label: 'Предмет',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: SUBJECT_OPTIONS,
    customFilter: (value: any) => value.id
  },
  {
    name: 'isHidden',
    label: 'Скрыт',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: BOOLEAN_OPTIONS,
    customFilter: (value: any) => Boolean(Number(value.id))
  },
  {
    name: 'dateFrom',
    label: 'Дата начала',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => [formatForFilter(value, 'start'), formatForFilter(value, 'end')]
  },
  {
    name: 'dateTo',
    label: 'Дата окончания',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => [formatForFilter(value, 'start'), formatForFilter(value, 'end')]
  }
];

export default filterFields;
