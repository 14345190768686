import { Typography } from '@mui/material';
import { EXERCISE_TYPE_NAME } from 'constants/global';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { ExerciseType } from '@generated/graphql';

interface ExerciseItemProps {
  onCloseDrawer: () => void;
  exercise: {
    id: string;
    title: string;
    type: ExerciseType;
  };
}

const ExerciseItem = ({ onCloseDrawer, exercise }: ExerciseItemProps) => {
  const { exerciseId, courseId } = useParams();
  const navigate = useNavigate();

  const navigateToExercise = (id: string) => {
    navigate(`/${ROUTES.COURSES}/${courseId}/${id}/`);
    onCloseDrawer();
  };
  return (
    <Typography
      key={`exercise-${exercise.id}`}
      component='li'
      onClick={() => navigateToExercise(exercise.id)}
      sx={{
        ...(exerciseId === exercise.id
          ? {
              color: 'primary.main',
              listStyleType: 'disclosure-closed'
            }
          : undefined),
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.main'
        }
      }}
    >
      {`${EXERCISE_TYPE_NAME[exercise.type]}: ${exercise.title}`}
    </Typography>
  );
};

export default ExerciseItem;
