import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { BOOLEAN_OPTIONS, ROLE_OPTIONS } from 'constants/global';

const filterFields = () => [
  {
    name: 'numericId',
    label: 'ID',
    inputType: InputTypes.TEXT,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value)
  },
  {
    name: 'fullName',
    label: 'ФИО',
    inputType: InputTypes.TEXT
  },
  {
    name: 'role',
    label: 'Роль',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: ROLE_OPTIONS,
    customFilter: (value: any) => value.id
  },
  {
    name: 'createdAt',
    label: 'Дата создания',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => value
  },
  {
    name: 'isBlocked',
    label: 'Заблокирован',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: BOOLEAN_OPTIONS,
    customFilter: (value: any) => Boolean(Number(value.id))
  }
];

export default filterFields;
