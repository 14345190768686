import { Button } from '@mui/material';
import MenuButton from 'components/buttons/MenuButton';
import { FC, useContext } from 'react';
import { ModalContext } from 'context/modalContext';
import { useDeleteCourseCardMutation, usePublishOrHideCourseCardMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

const ActionButtons: FC<{ card: any }> = ({ card }) => {
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);

  const [publishOrHideCard] = usePublishOrHideCourseCardMutation();
  const [deleteCard] = useDeleteCourseCardMutation();

  const { isHidden } = card;

  const courseTitle = card?.course?.title;

  const onPublishOrHide = () =>
    handleOpen({
      title: isHidden ? 'Опубликовать карточку' : 'Скрыть карточку',
      content: isHidden
        ? `Вы уверены, что хотите опубликовать карточку курса «${courseTitle}»?`
        : `Вы уверены, что хотите скрыть курс карточку курса «${courseTitle}»?`,
      buttonText: isHidden ? 'Опубликовать' : 'Скрыть',
      handleClick: async () =>
        publishOrHideCard({ variables: { courseCardId: card?.id } })
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
    });

  const onDelete = () =>
    handleOpen({
      title: 'Удалить карточку',
      content: `Вы уверены, что хотите удалить карточку курса
                       «${courseTitle}»? Отменить это действие будет невозможно!`,
      handleClick: async () =>
        deleteCard({ variables: { courseCardId: card?.id } })
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
    });

  return (
    <MenuButton>
      <Button onClick={onPublishOrHide}>{isHidden ? 'Опубликовать' : 'Скрыть'}</Button>
      <Button onClick={onDelete} sx={{ color: 'error.main' }}>
        Удалить
      </Button>
    </MenuButton>
  );
};

export default ActionButtons;
