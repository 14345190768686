import React, { FC } from 'react';
import { ElementFileType } from '@generated/graphql';
import { Box, Stack } from '@mui/material';
import { ButtonWithFile, Dropzone, TextFieldControl } from 'components/inputs';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { getFileName } from 'helpers/common';
import { CurrentFileFieldName, ExerciseForm, IFile } from '../../../types';
import AttachDownloadFile from './AttachDownloadFile';
import ImageFile from './ImageFile';

interface SelectFilesProps {
  field: IFile;
  form: UseFormReturn<ExerciseForm>;
  fieldArray: UseFieldArrayReturn;
  index: number;
  elementIndex: number;
  disabled?: boolean;
}

const SelectFiles: FC<SelectFilesProps> = ({ field, form, disabled, fieldArray, index, elementIndex }) => {
  const { update } = fieldArray;
  const { getValues } = form;
  const isFileExist = !!field.file;
  const type = field?.type;
  const isCover = type === ElementFileType.Cover;

  const deleteFile = () => {
    update(index, { type });
  };

  const currentName: CurrentFileFieldName = `elements.${elementIndex}.files.${index}`;

  const currentFile = getValues(currentName) || {};

  const currentFileNameWithExtension = currentFile?.file?.name;

  const fileExtension = getFileName(currentFileNameWithExtension, 'extension');

  if (isFileExist) {
    if (isCover) return <ImageFile {...{ index, form, fieldArray, currentName, type }} />;
    return (
      <Stack spacing={1}>
        <TextFieldControl
          name={`${currentName}.fileName`}
          label='Название файла'
          rules={{ required: true }}
          endIcon={fileExtension ? `.${fileExtension}` : ''}
        />
        <ButtonWithFile
          onDelete={() => deleteFile()}
          file={field.file}
          disabled={disabled}
          fileName={`${field.fileName}.${fileExtension}`}
        />
      </Stack>
    );
  }
  if (isCover) {
    return (
      <Box width='100%' height='100%' overflow='hidden'>
        <Dropzone
          setValue={(name: string, newFile: IFile) => {
            if (name === 'coverFile') {
              update(index, {
                ...getValues(currentName),
                file: newFile
              });
            }
          }}
        />
      </Box>
    );
  }
  return <AttachDownloadFile {...{ fieldArray, elementIndex, index, form, isCover, disabled, currentName }} />;
};

export default SelectFiles;
