import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import client from 'config/apolloConfig';
import { ModalProvider } from 'context/modalContext';
import { ToastProvider } from 'context/toastContext';
import { AuthProvider } from 'context/authContext';
import router from './router';
import createAppTheme from './theme';

const App = () => {
  const theme = createAppTheme();

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ToastProvider>
              <ModalProvider>
                <RouterProvider router={router} />
              </ModalProvider>
            </ToastProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
