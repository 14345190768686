import { formatISODate } from 'helpers/date';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME } from 'constants/global';
import { UserAnswerStatus } from '@generated/graphql';
import { Chip } from '@mui/material';

import React from 'react';

export type UserExercise = {
  __typename?: 'Exercise';
  id: string;
  title: string;
  lesson: { __typename?: 'Lesson'; id: string; block: { __typename?: 'Block'; id: string; courseId: string } };
  userFileAnswer?: {
    __typename?: 'UserAnswer';
    id: string;
    createdAt: any;
    updatedAt: any;
    status?: UserAnswerStatus | null;
    userId: string;
  } | null;
  userStatistic: {
    __typename?: 'ExerciseStatistic';
    scoreA?: number | null;
    scoreB?: number | null;
    maxScoreB?: number | null;
    maxScoreA?: number | null;
  };
};

const USER_EXERCISES_TABLE = [
  {
    title: 'Название ДЗ',
    value: (exercise: UserExercise) => exercise.title || '—',
    loadingValue: 'Название ДЗ'
  },
  {
    title: 'Дата изменения',
    value: (exercise: UserExercise) =>
      exercise.userFileAnswer?.updatedAt ? formatISODate(exercise.userFileAnswer?.updatedAt, 'dd.MM.yyyy HH:mm') : '—',
    loadingValue: '22.22.2222'
  },
  {
    title: 'Дата отправки ДЗ',
    value: (exercise: UserExercise) =>
      exercise.userFileAnswer?.createdAt ? formatISODate(exercise.userFileAnswer?.createdAt, 'dd.MM.yyyy HH:mm') : '—',
    loadingValue: '22.22.2222'
  },
  {
    title: 'Оценка часть А',
    value: (exercise: UserExercise) => exercise.userStatistic.scoreA,
    loadingValue: '43'
  },
  {
    title: 'Оценка часть Б',
    value: (exercise: UserExercise) => exercise.userStatistic.scoreB,
    loadingValue: '43'
  },
  {
    title: 'Статус',
    value: (exercise: UserExercise) => {
      const params = {
        label: HOMEWORK_STATUS_NAME.DRAFT,
        color: HOMEWORK_STATUS_COLOR.DRAFT
      };
      const isExistPartB = !!exercise.userStatistic.maxScoreB;
      const isExistPartA = !!exercise.userStatistic.maxScoreA;

      if (isExistPartB && exercise.userFileAnswer?.status) {
        params.color = HOMEWORK_STATUS_COLOR[exercise.userFileAnswer?.status];
        params.label = HOMEWORK_STATUS_NAME[exercise.userFileAnswer?.status];
      }
      if (!isExistPartB && isExistPartA && exercise.userStatistic.scoreA !== null) {
        params.color = HOMEWORK_STATUS_COLOR.AUTOCOMPLETE;
        params.label = HOMEWORK_STATUS_NAME.AUTOCOMPLETE;
      }
      if (!isExistPartA && !isExistPartB) {
        params.color = HOMEWORK_STATUS_COLOR.DRAFT;
        params.label = 'Нет заданий';
      }
      return <Chip size='small' label={params.label} sx={{ backgroundColor: params.color }} variant='outlined' />;
    },
    loadingValue: 'ХXXXXXXXXX'
  }
];

export default USER_EXERCISES_TABLE;
