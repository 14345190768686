import React, { FC } from 'react';
import { Autocomplete, AutocompleteProps, InputAdornment, Stack, TextField } from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

type AutocompleteControlProps = {
  options?: any[] | undefined | null;
  name: string;
  optionName?: string;
  search?: boolean;
  equalityParam?: string;
  rules?: UseControllerProps['rules'];
  multiple?: AutocompleteProps<any, any, any, any>['multiple'];
  disabled?: AutocompleteProps<any, any, any, any>['disabled'];
  onInputChange?: AutocompleteProps<any, any, any, any>['onInputChange'];
  placeholder?: string;
  loading?: AutocompleteProps<any, any, any, any>['loading'];
  getOptionLabel?: AutocompleteProps<any, any, any, any>['getOptionLabel'];
  freeSolo?: AutocompleteProps<any, any, any, any>['freeSolo'];
  label?: string;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
};

const AutocompleteControl: FC<AutocompleteControlProps> = ({
  options = [],
  name,
  optionName = 'displayName',
  multiple = false,
  disabled = false,
  onInputChange,
  placeholder,
  search = false,
  equalityParam = 'id',
  rules,
  loading = false,
  getOptionLabel = (option) => option[optionName] || '',
  freeSolo = false,
  label,
  startIcon,
  ...props
}) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules });

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeholder || 'Выберите из списка'}
      helperText={error?.message}
      error={!!error}
      label={label}
      InputProps={{
        ...params.InputProps,
        startAdornment:
          params.InputProps.startAdornment ||
          (startIcon && <InputAdornment position='start'>{startIcon}</InputAdornment>)
      }}
    />
  );

  const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    const text = getOptionLabel(option) || option[optionName];
    return (
      <li {...optionProps} key={`${option[equalityParam]}-${text}`}>
        {text}
      </li>
    );
  };

  return (
    <Stack spacing={0.5} sx={{ width: props.fullWidth ? '100%' : 'auto' }}>
      <Autocomplete
        fullWidth={props.fullWidth}
        size='small'
        value={multiple ? field.value : field.value || null}
        onBlur={field.onBlur}
        multiple={multiple}
        options={options || []}
        filterOptions={search ? (x) => x : undefined}
        disabled={disabled}
        loading={loading}
        freeSolo={freeSolo}
        loadingText='Загрузка...'
        noOptionsText='Нет данных'
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderOption={renderOption}
        isOptionEqualToValue={(option, value) => option[equalityParam] === (value[equalityParam] || '')}
        onChange={(_, data, reason) => (reason === 'clear' ? field.onChange(null) : field.onChange(data))}
        onInputChange={onInputChange}
      />
    </Stack>
  );
};

export default AutocompleteControl;

AutocompleteControl.defaultProps = {
  equalityParam: 'id',
  rules: undefined,
  search: false,
  optionName: 'displayName',
  multiple: false,
  disabled: false,
  onInputChange: undefined,
  placeholder: undefined,
  loading: false,
  getOptionLabel: undefined,
  freeSolo: false,
  options: [],
  label: undefined,
  startIcon: undefined
};
