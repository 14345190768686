import React, { FC } from 'react';
import { Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/TextFieldControl';
import InsertButton from 'components/buttons/InsertButton';
import { UseFormReturn } from 'react-hook-form';

type VideoProps = {
  elementIndex: number;
  disabled?: boolean;
  form: UseFormReturn;
};

const Video: FC<VideoProps> = ({ elementIndex, disabled, form }) => (
  <Stack sx={{ mt: 3 }}>
    <InsertButton form={form} inputName={`elements.[${elementIndex}].kinescopeId`}>
      <TextFieldControl
        label='Kinescope ID'
        placeholder='https://kinescope.io/12337899effdf'
        name={`elements.[${elementIndex}].kinescopeId`}
        rules={{ required: true }}
        disabled={disabled}
      />
    </InsertButton>
  </Stack>
);

export default Video;

Video.defaultProps = {
  disabled: false
};
