import { formatISODate } from 'helpers/date';
import { SUBJECT_NAME } from 'constants/global';
import { CourseSubject } from '@generated/graphql';

const COURSES_TABLE = [
  {
    title: 'ID',
    value: (course: any) => course.numericId || '—',
    loadingValue: '1355'
  },
  {
    title: 'Название',
    value: (course: any) => course.title || '—',
    loadingValue: '1355'
  },
  {
    title: 'Предмет',
    value: ({ subject }: { subject: CourseSubject }) => (subject ? SUBJECT_NAME[subject] : '—'),
    loadingValue: 'Биология'
  },
  {
    title: 'Начало',
    value: (course: any) => (course.dateFrom ? formatISODate(course.dateFrom) : '—'),
    loadingValue: '21.21.2222'
  },
  {
    title: 'Окончание',
    value: (course: any) => (course.dateTo ? formatISODate(course.dateTo) : '—'),
    loadingValue: '21.21.2222'
  },
  {
    title: 'Участники',
    value: (course: any) => course.usersCount || '0',
    loadingValue: '100'
  },
  {
    title: 'Скрыт',
    value: (course: any) => (course.isHidden ? 'Да' : 'Нет'),
    loadingValue: '100'
  },
  {
    title: 'Дата создания',
    value: (course: any) => (course.createdAt ? formatISODate(course.createdAt) : '—'),
    loadingValue: '100'
  }
];

export default COURSES_TABLE;
