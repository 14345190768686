import { array, bool, boolean, object, string } from 'yup';

const blockValidation = object().shape({
  blocks: array()
    .of(
      object().shape({
        id: string(),
        title: string().required(' '),
        trial: bool(),
        lessons: array()
          .of(
            object().shape({
              id: string(),
              title: string().required(' '),
              trial: boolean(),
              exercises: array().of(
                object().shape({
                  id: string(),
                  title: string().required(' '),
                  type: object()
                    .shape({ id: string().required(' '), displayName: string().required(' ') })
                    .default(undefined)
                    .required(' ')
                })
              )
            })
          )
          .min(1)
      })
    )
    .min(1)
});

export default blockValidation;
