import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import Editor from './Editor';

type EditorControlProps = {
  name: string;
  label: string;
  rules?: UseControllerProps['rules'];
  disabled?: boolean;
};

const Index: FC<EditorControlProps> = ({ name, label, rules, disabled }) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules });
  return (
    <Stack spacing={0.5}>
      <Typography>{label}</Typography>

      <Box sx={{ position: 'relative' }}>
        <Editor onChange={field.onChange} error={!!error} value={field.value || ''} disabled={disabled} />
      </Box>
    </Stack>
  );
};

export default Index;

Index.defaultProps = {
  disabled: false,
  rules: undefined
};
