import { Card, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { UserAnswerQuery } from '@generated/graphql';
import { formatISODate } from 'helpers/date';
import { ButtonWithFile } from 'components/inputs';

const HistoryMessages: FC<{ messages: UserAnswerQuery['userAnswerHistory']; studentId: string }> = ({
  messages,
  studentId
}) => (
  <Stack spacing={2}>
    {messages?.map((item) => {
      const isStudentMessage = studentId === item.user?.id;
      return (
        <Card component={Stack} spacing={2}>
          <Stack direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
            <Typography variant='h5'>{isStudentMessage ? 'Ответ пользователя' : 'Ответ куратора'}</Typography>
            {!!item?.createdAt && (
              <Typography variant='text6'>{formatISODate(item?.createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
            )}
          </Stack>
          {!isStudentMessage && item.score !== null && (
            <Typography sx={{ color: 'base.400', '& > span': { color: 'base.800' } }}>
              Количество баллов:
              <span>{` ${item.score}`}</span>
            </Typography>
          )}
          {item.message && (
            <Stack spacing={1}>
              <Typography sx={{ color: 'base.400' }}>Сообщение:</Typography>
              <Typography
                variant='text3'
                sx={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: item.message
                }}
              />
            </Stack>
          )}
          {item.files?.length ? (
            <Stack direction='row' spacing={1} flexWrap='wrap' rowGap={2}>
              {item.files.map((file) => (
                <ButtonWithFile file={file.file} />
              ))}
            </Stack>
          ) : null}
        </Card>
      );
    })}
  </Stack>
);

export default HistoryMessages;
