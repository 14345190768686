import { endOfDay, format, isValid, parse, parseISO, startOfDay } from 'date-fns';

const formatFromStringToDate = (date: string) => parse(date, 'yyyy-MM-dd', new Date());
const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

const checkIsDateValid = (date: any) => {
  if (isValid(date)) return true;
  const parsedDate = formatFromStringToDate(date);
  return isValid(parsedDate);
};

const formatISODate = (dateISO: string, dateFormat: string = 'dd.MM.yyyy') => format(parseISO(dateISO), dateFormat);

const formatForFilter = (date: string, partOfDay?: string) =>
  partOfDay !== 'end'
    ? format(startOfDay(formatFromStringToDate(date)), ISO_FORMAT)
    : format(endOfDay(formatFromStringToDate(date)), ISO_FORMAT);

export { checkIsDateValid, formatISODate, formatForFilter };
