import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { FC, useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

const CopyButton: FC<{ copyText: string; children: any }> = ({ copyText, children }) => {
  const { addToast } = useContext(ToastContext);

  return (
    <Stack direction='row' alignItems='center'>
      {children}
      <Tooltip title='Скопировать'>
        <IconButton
          sx={{ color: 'primary.main', mr: -1.25 }}
          onClick={() => {
            navigator.clipboard
              .writeText(copyText)
              .then(() => addToast({ type: ToastTypeEnum.SUCCESS, text: 'Текст успешно скопирован' }));
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default CopyButton;
