import { Components, formControlLabelClasses, outlinedInputClasses, Theme } from '@mui/material';

export default {
  MuiCssBaseline: {
    styleOverrides: (themeParam) => `
        body {
          background-color: ${themeParam.palette.background.default};
        }
      `
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.text2,
        width: 70,
        height: 70
      })
    }
  },
  MuiChip: {
    variants: [
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          ...theme.typography.h3,
          padding: '2px 12px'
        })
      }
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.base[500],
        color: theme.palette.base[100],
        borderRadius: 30,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.base[500],
          color: theme.palette.base[300],
          opacity: 1
        }
      }),
      colorError: ({ theme }) => ({
        backgroundColor: theme.palette.base[500],
        color: theme.palette.error.main
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.text6,
        padding: '4px 8px'
      }),
      sizeMedium: ({ theme }) => ({
        ...theme.typography.h6,
        padding: '7px 12px',
        lineHeight: 'inherit'
      })
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1,
        textTransform: 'initial',
        borderRadius: 30
      },
      sizeSmall: ({ ownerState }) => ({
        ...(ownerState.startIcon || ownerState.endIcon ? { padding: '6px 12px' } : { padding: '10px 16px' })
      }),
      sizeMedium: ({ ownerState }) => ({
        ...((ownerState.startIcon || ownerState.endIcon) && { padding: '12px 19px' })
      }),
      sizeLarge: ({ ownerState }) => ({
        ...(ownerState.startIcon || ownerState.endIcon ? { padding: '12px 19px' } : { padding: '20px 16px' })
      }),
      iconSizeSmall: {
        marginLeft: 8
      },
      iconSizeMedium: {
        marginLeft: 14
      },
      iconSizeLarge: {
        marginLeft: 8
      },
      endIcon: {
        marginLeft: 10
      },
      contained: ({ theme }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette } = theme;
        return {
          padding: 16,
          color: palette.base[isLightMode ? 800 : 100]
        };
      },
      containedPrimary: ({ theme }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette, shadows } = theme;
        return {
          boxShadow: shadows[0],
          color: palette.base[100],
          '&:hover': {
            boxShadow: shadows[0]
          },
          '&.Mui-disabled': {
            backgroundColor: palette.base[400],
            color: palette.base[isLightMode ? 300 : 400]
          }
        };
      },
      containedSecondary: ({ theme }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette, shadows } = theme;
        return {
          backgroundColor: palette.base[500],
          boxShadow: shadows[isLightMode ? 1 : 0],
          '&:hover': {
            boxShadow: shadows[isLightMode ? 1 : 2],
            color: palette.base[100]
          },
          '&.Mui-disabled': {
            backgroundColor: palette.base[500],
            color: palette.base[isLightMode ? 300 : 400]
          }
        };
      },
      text: ({ theme }) => ({
        color: theme.palette.base[400],
        '&:hover': {
          color: theme.palette.mode === 'light' ? theme.palette.base[800] : theme.palette.base[100],
          backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
          color: theme.palette.base[300]
        }
      })
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        padding: 16
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '10px',
        position: 'relative',
        [`&:not(.Mui-error):hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.base[300]
        },
        [`&:not(.Mui-error).Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.base[300]
        },
        [`&.Mui-error .${outlinedInputClasses.input}`]: {
          color: theme.palette.error.main
        }
      }),
      input: ({ theme }) => ({
        color: theme.palette.base[500],
        '&:focus': {
          borderColor: 'transparent',
          outline: 'none'
        },
        '&::placeholder, &::placeholder': {
          color: theme.palette.base[300],
          opacity: 1
        }
      }),
      notchedOutline: ({ theme }) => ({
        border: '2px solid',
        borderColor: theme.palette.base[300],
        '&.Mui-error': {
          borderColor: theme.palette.error.main
        }
      }),
      adornedEnd: ({ theme }) => ({
        '&.Mui-error .MuiInputAdornment-root': {
          color: theme.palette.error.main
        },
        '.MuiInputAdornment-root': {
          color: theme.palette.base[500]
        }
      })
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.base[300],
        padding: 8,
        '&.Mui-disabled': {
          color: theme.palette.base[300]
        },
        '& .MuiSvgIcon-root': { width: 20, height: 20 }
      })
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${formControlLabelClasses.label}`]: {
          ...theme.typography.text4
        },
        [`& .${formControlLabelClasses.label}.Mui-disabled`]: {
          color: theme.palette.base[300]
        }
      })
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.base[300],
        padding: 8,
        '&.Mui-disabled': {
          color: theme.palette.base[300]
        },
        '& .MuiSvgIcon-root': { width: 20, height: 20 }
      })
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 20,
        '& > .MuiDialogTitle-root+.MuiDialogContent-root': {
          paddingTop: 8
        }
      }
    }
  },
  MuiIconButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: ({ theme }) => {
          const isLightMode = theme.palette.mode === 'light';
          const { palette, shadows } = theme;
          return {
            backgroundColor: palette.base[500],
            boxShadow: shadows[3],
            height: 48,
            width: 48,
            '&:hover': {
              backgroundColor: palette.base[500],
              boxShadow: shadows[isLightMode ? 1 : 2]
            },
            '&.Mui-disabled': {
              backgroundColor: palette.base[500],
              color: palette.base[isLightMode ? 300 : 400]
            }
          };
        }
      }
    ]
  },
  MuiTypography: {
    defaultProps: {
      fontFamily: 'Inter'
    },
    styleOverrides: {
      root: {
        '& ol': {
          listStyle: 'none',
          counterReset: 'customCounter',
          '& > li::before': {
            content: "counter( customCounter ) ') '",
            counterIncrement: 'customCounter'
          }
        },
        '& ol, & ul': {
          padding: 0
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        '&.MuiOutlinedInput-input': {
          ...theme.typography.text4,
          padding: 12,
          color: theme.palette.base[700]
        }
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.text4,
        padding: '8px 12px'
      })
    }
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&.MuiMenu-list': {
          padding: 0
        }
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 0,
        position: 'absolute',
        bottom: -20
      }
    }
  }
} as Components<Theme>;
