import { TextFieldControl } from 'components/inputs';

interface IExerciseLinkProps {
  elementIndex: number;
}

const ExerciseLink = ({ elementIndex }: IExerciseLinkProps) => (
  <TextFieldControl multiline label='Описание домашнего задания' name={`elements.${elementIndex}.description`} />
);

export default ExerciseLink;
