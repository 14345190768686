import CircularLoading from 'components/CircularLoading';
import { useParams } from 'react-router-dom';
import { Chip, Stack, Typography } from '@mui/material';
import TabLayout from 'layouts/TabLayout';
import { useUserHeaderQuery } from '@generated/graphql';
import { ROLE_NAME } from 'constants/global';
import React, { useEffect } from 'react';
import PersonalInfoTab from 'pages/User/view/tabs/PersonalInfoTab';
import Courses from './tabs/Courses';
import HeaderButtons from './HeaderButtons';

const TABS = [
  { title: 'Личная информация', value: 'personal', component: <PersonalInfoTab /> },
  { title: 'Курсы', value: 'courses', component: <Courses /> }
];

const UserView = () => {
  const { userId } = useParams();
  const { data, loading, error } = useUserHeaderQuery({
    variables: { userId: userId! },
    skip: !userId
  });
  const user = data?.user;

  useEffect(() => {
    document.title = user?.firstName ? `Пользователь ${user?.firstName}` : 'Пользователь';
  }, [user?.firstName]);

  return (
    <Stack>
      {loading || !!error ? (
        <CircularLoading />
      ) : (
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Stack spacing={2}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography variant='h1' sx={{ fontWeight: 500 }}>
                {`Пользователь ${[user?.lastName, user?.firstName, user?.middleName].join(' ')}`}
              </Typography>
              {user?.isBlocked && <Chip label='Заблокирован' size='small' />}
            </Stack>

            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              {`ID ${user?.numericId}${user?.role ? `, ${ROLE_NAME[user?.role]}` : ''}`}
            </Typography>
          </Stack>
          <HeaderButtons user={user} />
        </Stack>
      )}
      <TabLayout searchParam={userId} tabs={TABS} firstTab='personal' />
    </Stack>
  );
};

export default UserView;
