import { CourseCardType, useGetCourseHeaderQuery } from '@generated/graphql';
import { Link, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { Button, Stack, Typography } from '@mui/material';
import TabLayout from 'layouts/TabLayout';
import CardsPage from 'pages/Course/view/Cards/CardsPage';
import { COURSE_CARD_TITLE } from 'constants/global';

const CourseCards = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { data, loading, error } = useGetCourseHeaderQuery({
    variables: {
      courseId: courseId!
    }
  });

  const course = data?.course;

  const TABS = Object.entries(COURSE_CARD_TITLE).map((card) => ({
    title: card[1],
    value: card[0],
    component: <CardsPage cardType={card[0] as CourseCardType} />
  }));

  if (loading || !!error) return <CircularLoading />;

  return (
    <>
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Stack spacing={2}>
          <Typography variant='h1' sx={{ fontWeight: 500 }}>
            {`Курс${courseId ? ` ${course?.title}` : ''}`}
          </Typography>
          {courseId ? (
            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              {`ID ${course?.numericId}`}
            </Typography>
          ) : null}
        </Stack>
        <Button size='small' variant='contained' component={Link} to={`/courses/${courseId}`}>
          К курсу
        </Button>
      </Stack>
      <TabLayout searchParam={`cards-${courseId}`} tabs={TABS} firstTab={CourseCardType.Advertising} />
    </>
  );
};

export default CourseCards;
