import React, { FC } from 'react';
import { SvgIcon, SxProps, Theme } from '@mui/material';
import styled from '@emotion/styled';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const COMPONENTS = {
  MOVE_UP: ArrowDropUpIcon,
  MOVE_DOWN: ArrowDropDownIcon,
  DELETE: CloseSharpIcon,
  EDIT: DriveFileRenameOutlineIcon,
  MOVE_RIGHT: ArrowRightIcon,
  MOVE_LEFT: ArrowLeftIcon
};

export enum ActionButtonEnum {
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  MOVE_RIGHT = 'MOVE_RIGHT',
  MOVE_LEFT = 'MOVE_LEFT'
}

type ActionButtonProps = {
  disabled?: boolean;
  isFilled?: boolean;
  handleClick: () => any;
  sx?: SxProps;
  type: ActionButtonEnum;
};

const StyledSvg = styled(SvgIcon)(
  ({ theme, isFilled, disabled }: { theme: Theme; isFilled: boolean; disabled: boolean }) => ({
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    border: `1px solid ${isFilled ? theme.palette.primary.main : theme.palette.base[400]}`,
    borderRadius: '5px',
    color: isFilled ? theme.palette.primary.main : theme.palette.base[400],
    cursor: disabled ? 'default' : 'pointer',
    width: 24,
    height: 24,
    margin: '1px 0'
  })
) as unknown as typeof SvgIcon;

const ActionButton: FC<ActionButtonProps> = ({ handleClick, isFilled, disabled, sx, type }) => (
  <StyledSvg
    sx={sx}
    isFilled={isFilled}
    onClick={!disabled ? handleClick : undefined}
    disabled={disabled}
    component={COMPONENTS[type]}
  />
);
export default ActionButton;
