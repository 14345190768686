import { Stack, Typography } from '@mui/material';
import TableLayout from 'layouts/TableLayout';
import React, { useEffect } from 'react';
import { AdminUserListOrderFields, OrderType, useUsersQuery } from '@generated/graphql';
import USERS_TABLE from './table';
import filterFields from './filterFields';

const UsersList = () => {
  const { data, loading, error, refetch } = useUsersQuery({
    variables: { page: 0, limit: 10, order: { field: AdminUserListOrderFields.NumericId, order: OrderType.Asc } }
  });

  const courses = data?.users?.data;
  const totalCount = data?.users?.totalCount;

  useEffect(() => {
    document.title = 'Пользователи';
  }, []);

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
        <Typography variant='h1'>Пользователи</Typography>
      </Stack>
      <Stack spacing={3}>
        <TableLayout
          {...{ error, totalCount, refetch }}
          loading={loading}
          data={courses}
          tableStructure={USERS_TABLE}
          filtersFunc={filterFields}
          defaultOrder={{ field: AdminUserListOrderFields.NumericId, order: 'asc' }}
        />
      </Stack>
    </>
  );
};

export default UsersList;
