import { useUserBlocksQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import TableLayout from 'layouts/TableLayout';
import USER_BLOCKS_TABLE from 'pages/Course/view/UserBlocks/table';
import { Stack, Typography } from '@mui/material';
import { createUserName } from 'helpers/common';

const UserBlocks = () => {
  const { courseId, userId } = useParams<{ courseId: string; userId: string }>();

  const defaultFilter = {
    courseId: courseId!
  };

  const { data, loading, error, refetch } = useUserBlocksQuery({
    variables: {
      filter: defaultFilter,
      limit: 10,
      page: 0,
      userId: userId!,
      courseId: courseId!
    }
  });
  const blocks = data?.blocks.data;
  const totalCount = data?.blocks.totalCount;
  const user = data?.user;
  const course = data?.course;

  return (
    <Stack spacing={2}>
      <Typography variant='h1'>{`${createUserName(user)} - ${course?.title}`}</Typography>
      <TableLayout
        refetch={refetch}
        defaultFilter={defaultFilter}
        data={blocks}
        loading={loading}
        action={() => {}}
        tableStructure={USER_BLOCKS_TABLE(refetch, user)}
        error={error}
        totalCount={totalCount}
        rowSx={() => ({ cursor: 'default' })}
      />
    </Stack>
  );
};

export default UserBlocks;
